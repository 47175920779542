import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('../pages/home.vue'),
  },
  {
    path: '/sportsbook',
    component: () => import('../pages/sportsbook.vue'),  
  },
  {
    path: '/forgot-password',
    component: () => import('../pages/forgot-password.vue'),  
  },
  {
    path: '/casino',
    component: () => import('../pages/casino.vue'),  
  },
  {
    path: '/racebook',
    component: () => import('../pages/racebook.vue'),  
  },
  {
    path: '/live-betting',
    component: () => import('../pages/live-betting.vue'),  
  },
  {
    path: '/props-builder',
    component: () => import('../pages/props-builder.vue'),  
  },
  {
    path: '/house-rules',
    component: () => import('../pages/house-rules.vue'),  
  },
  {
    path: '/sports-rules',
    component: () => import('../pages/sports-rules.vue'),  
  },
  {
    path: '/responsible-gaming',
    component: () => import('../pages/responsible-gaming.vue'),  
  },
  {
    path: '/responsible-gambling',
    component: () => import('../pages/responsible-gambling.vue'),  
  },
  {
    path: '/privacy-policy',
    component: () => import('../pages/privacy-policy.vue'),  
  },
  {
    path: '/terms-and-conditions',
    component: () => import('../pages/terms-and-conditions.vue'),  
  },
  {
    path: '/contact-us',
    component: () => import('../pages/contact-us.vue'),  
  },
  {
    path: '/horse-rules',
    component: () => import('../pages/horse-rules.vue'),  
  },
  {
    path: '/betting-guide',
    component: () => import('../pages/betting-guide.vue'),  
  },
  {
    path: '/horse-racing-glossary',
    component: () => import('../pages/horse-racing-glossary.vue'),  
  },
  {
    path: '/horse-racing-guide',
    component: () => import('../pages/horse-racing-guide.vue'),  
  },
  {
    path: '/sports-betting-types-parlay',
    component: () => import('../pages/sports-betting-types-parlay.vue'),  
  },
  {
    path: '/sports-betting-types-points',
    component: () => import('../pages/sports-betting-types-points.vue'),  
  },
  {
    path: '/sports-betting-types-teaser',
    component: () => import('../pages/sports-betting-types-teaser.vue'),  
  },
  {
    path: '/sports-betting-types-bets',
    component: () => import('../pages/sports-betting-types-bets.vue'),  
  },
  {
    path: '/sports-betting-types-wagers',
    component: () => import('../pages/sports-betting-types-wagers.vue'),  
  },
  {
    path: '/sports-betting-types-reverse',
    component: () => import('../pages/sports-betting-types-reverse.vue'),  
  },
  {
    path: '/sports-betting-types-robin',
    component: () => import('../pages/sports-betting-types-robin.vue'),  
  },
  {
    path: '/sports-betting-types-futures',
    component: () => import('../pages/sports-betting-types-futures.vue'),  
  },
  {
    path: '/same-game-parlay-rules',
    component: () => import('../pages/same-game-parlay-rules.vue'),  
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
})
export default router