<template>
    <div class="dropdown">
      <a @click="toggleDropdown">Reports</a>
        <ul @mouseleave="toggleDropdown" class="items-container" v-show="isOpen" ref="dropdownContent">
          <li ><a class="line" :href="agentClassic">Agents classic</a></li>
          <li ><a class="line" :href="agentNew">Agents new</a></li>
        </ul>
    </div>
  </template>
  
  <script>
  export default {
    name: "Dropdown-component",
    setup(){
      const agentClassic = "https://agents.bigstakebets.com";
      const agentNew = "https://adm.bigstakebets.com/ ";
    return {
      agentClassic,
      agentNew
    }

    },
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      },
    },
  };
  </script>
  
  