<template>
    <a href="/" class="brand-header">
        <img width="auto" height="auto" class="brand-header__img" src="@/assets/images/logo.png" alt="BigStakeBets" />
    </a>
</template>

<script>
    export default {
        name: "BrandComponent",
        setup() {

        },
    };
</script>
