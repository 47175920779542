<template>
  <footer class="footer">
    <a href="#go-up" class="go-to-up"><img src="@/assets/images/icon-up.svg" alt="go-up"></a>
    <div class="footer-content">
      <section class="agent-buttons">
        <div class="img-container">
          <img src="@/assets/images/logo.png" alt="BeatTheTotal" width="auto" height="auto">
        </div>
        <a class="primary-button primary-button__classic" :href="agentClassic" target="_blank">AGENT ADMIN</a>
      </section>
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routes"
          :key="index">
          <div class="report" v-if="route.class === 'dropdown'">
            <Dropdown></Dropdown>
          </div>
          <div v-else>
            <router-link :to="route.route">{{ route.name }}</router-link>
          </div>
        </li>
      </ul>
    </div>
    <section class="footer__copyright">
    </section>
  </footer>
  <!-- <div class="footer-copyright">
    Wager Your Mojo © 2023 TERMS AND CONDITIONS
  </div> -->
</template>

<script>
import Dropdown from '@/components/dropdownFooter.vue'

export default {
  name: "FooterComponent",
  components: {
    Dropdown
  },
  setup() {
    const agentClassic = "https://agents.bigstakebets.com/AgentSiteV2/";
    // const agentNew = "https://adm.bigstakebets.com/";
    return {
      agentClassic,
      // agentNew,
      routes: [
        {
          class: '',
          name: 'Sports Rules',
          route: 'sports-rules'
        },
        // {
        //   class: '',
        //   name: 'Contact Us',
        //   route: 'contact-us'
        // },
        {
          class: '',
          name: 'House Rules',
          route: 'house-rules'
        },
        {
            route      :  'same-game-parlay-rules',
            name        : 'Same Game Parlay Rules'
        },
        {
          class: '',
          name: 'Terms and Conditions',
          route: 'terms-and-conditions'
        },
/*         {
          class: 'dropdown',
          name: 'Reports',

        }, */
      ]
    }
  },

};
</script>
