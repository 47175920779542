<template>
<div class="mobile-menu" v-bind:class="{ 'hidden-mobile': !showComponent }">
    <div class="menu-mobile-item" @click="openModal1"><span class="material-symbols-rounded">person</span><span>Log in</span></div>
    <!-- <menu-component/> -->
    <ModalComponent ref="modal" type="classic"/>
    <ModalComponent ref="modal2" type="betslip"/>
</div>
</template>

<script>
    // import MenuComponent from '@/components/menu.vue'
    import ModalComponent from '@/components/modal.vue'
    export default {
        name: 'MobileMenu',
        showComponent: true,
        components: {
            // MenuComponent,
            ModalComponent
        },
        methods: {
            openModal1() {
                this.$refs.modal.openModal();
            },
            openModal2() {
                this.$refs.modal2.openModal();
            }
        },
        props: {
            items: {
            type: Array,
            required: true
            }
        },
        data() {
            return {
            isOpen: false
            }
        }
    };
</script>